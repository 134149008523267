import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer, EuiHideFor, EuiShowFor } from '@elastic/eui';
import NavButtons from 'components/Motion_Detection/Alarm_Notification/NavButtons';
import ForumBox from 'components/Motion_Detection/Alarm_Notification/ForumBox';
import Table from 'components/Motion_Detection/Alarm_Notification/SMTP_Servers/Table';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "SMTP Server Login",
  "path": "/Motion_Detection/Alarm_Notification/SMTP_Servers/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "SMTP Server Addresses for commonly used Email Providers",
  "image": "../../MD_SearchThumb_AlarmNotification_1080p.png",
  "social": "/images/Search/MD_SearchThumb_AlarmNotification_1080p.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-Alarm-Notification_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='SMTP Server Login' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Setting up and activating the email alarm.' image='/images/Search/MD_SearchThumb_AlarmNotification_1080p.png' twitter='/images/Search/MD_SearchThumb_AlarmNotification_1080p.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/Alarm_Benachrichtigung/SMTP_Servers/' locationFR='/fr/Motion_Detection/Alarm_Notification/SMTP_Servers/' crumbLabel="Notifications" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "alarmbenachrichtigung",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#alarmbenachrichtigung",
        "aria-label": "alarmbenachrichtigung permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarmbenachrichtigung`}</h1>
    <h3 {...{
      "id": "smtp-server-adressen-für-die-meistverbreiteten-e-mail-provider",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#smtp-server-adressen-f%C3%BCr-die-meistverbreiteten-e-mail-provider",
        "aria-label": "smtp server adressen für die meistverbreiteten e mail provider permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SMTP Server Adressen für die meistverbreiteten E-Mail Provider`}</h3>
    <h3 {...{
      "id": "smtp-server-addresses-for-commonly-used-email-providers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#smtp-server-addresses-for-commonly-used-email-providers",
        "aria-label": "smtp server addresses for commonly used email providers permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SMTP Server Addresses for commonly used Email Providers`}</h3>
    <p>{`The Simple Mail Transfer Protocol (SMTP) is the internet standard that is used to send Emails. SMTP uses the default TCP port 25 to send mails and the Port 587 to receive them. The standard port for SSL secured connections is 465. The camera needs to know the IP address of its initial SMTP server and this has to be given as part of its configuration (usually given as a DNS name like smtp.gmail.com). This server will deliver outgoing messages on behalf of the user. The SMTP server typically requires authentication of clients by credentials before allowing access. Usually the users email address is used as user name and the password that is used to login to the email providers website is also the SMTP password.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <Table mdxType="Table" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      